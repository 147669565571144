<template>
  <div>
    <b-row>
      <b-col class="d-flex justify-content-between pb-1 align-items-center">
        <h1 class="font-weight-bolder">
          {{ $t('users') }}
        </h1>
        <b-button
          :to="{ name: 'new-user' }"
          variant="primary"
        >
          {{ $t('create', { type: $t('user') }) }}
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <users-table
          :users="users"
          :pagination="pagination"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import UsersTable from '@/views/users/UsersTable.vue'

export default {
  components: {
    UsersTable,
  },
  computed: {
    ...mapGetters('users', ['users', 'pagination']),
  },

  created() {
    this.fetchUsers({ by_active_status: true })
  },

  methods: {
    ...mapActions('users', ['fetchUsers']),
  },
}
</script>

<style></style>
